import type {
  FilterGroupMultiCheck,
  FilterGroupMultiSelect,
  FilterGroupSingleTag,
  FilterOptionMultiCheck,
  FilterOptionMultiSelect,
  FilterOptionSingleTag,
} from "~/models/filters";
import { type CategoryListItem, NAV_CATEGORY_CODE } from "~/models/products";

type FilterGroupType = FilterGroupMultiCheck | FilterGroupMultiSelect | FilterGroupSingleTag;

const multiSelectTreshold = 10;

export async function mapToFilterGroups(
  filters: Array<any>,
  categories: Array<CategoryListItem>,
): Promise<Array<FilterGroupType>> {
  const filterGroups = [] as Array<FilterGroupType>;

  for (const filter of filters) {
    if (filter.textFilter !== null) {
      const hasSelected = filter.textFilter.some((i: any) => i.isSelected);
      const filterOptions = filter.textFilter
        .filter((i: any) => i.value !== "" && (hasSelected ? i.countActive > 0 : i.count > 0));

      if (filterOptions.length === 0) {
        continue;
      }

      if (filter.id === NAV_CATEGORY_CODE) {
        const filterGroup = {
          id: filter.id,
          searchId: filter.searchId,
          title: filter.description,
          options: [],
        } as FilterGroupSingleTag;

        for (const filterOption of filterOptions) {
          filterGroup.options.push({
            label: getCategoryName(filterOption.value, categories),
            value: filterOption.value,
            count: hasSelected ? filterOption.countActive : filterOption.count,
          } as FilterOptionSingleTag);
        }

        filterGroup.options.sort((a: FilterOptionSingleTag, b: FilterOptionSingleTag) =>
          a.label.localeCompare(b.label));

        filterGroups.push(filterGroup);

        continue;
      }

      if (filterOptions.length > multiSelectTreshold) {
        const filterGroup = {
          id: filter.id,
          searchId: filter.searchId,
          title: filter.description,
          showClearAction: computed(() => { return filter.textFilter.filter((i: any) => i.isSelected).length > 0; }),
          selectedList: filter.textFilter.filter((i: any) => i.isSelected).map((i: any) => i.value),
          options: [],
        } as FilterGroupMultiSelect;

        for (const filterOption of filterOptions) {
          filterGroup.options.push({
            label: filterOption.value,
            value: filterOption.value,
            count: hasSelected ? filterOption.countActive : filterOption.count,
          } as FilterOptionMultiSelect);
        }

        filterGroup.options.sort((a: FilterOptionMultiSelect, b: FilterOptionMultiSelect) =>
          a.label.localeCompare(b.label));

        filterGroups.push(filterGroup);

        continue;
      }

      const filterGroup = {
        id: filter.id,
        searchId: filter.searchId,
        title: filter.description,
        options: [],
      } as FilterGroupMultiCheck;

      for (const filterOption of filterOptions) {
        filterGroup.options.push({
          label: filterOption.value,
          value: filterOption.value,
          count: hasSelected ? filterOption.countActive : filterOption.count,
          selected: filterOption.isSelected,
        } as FilterOptionMultiCheck);
      }

      filterGroup.options.sort((a: FilterOptionMultiCheck, b: FilterOptionMultiCheck) =>
        a.label.localeCompare(b.label));

      filterGroups.push(filterGroup);
    }
  }

  return filterGroups.sort((a: FilterGroupType, b: FilterGroupType) => a.title.localeCompare(b.title));
}
