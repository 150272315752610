import { CategoryList, type CategoryListItem } from "~/models/products";

export function getCategoryId(categoryCode: string): number | undefined {
  for (const [key, value] of Object.entries(CategoryList)) {
    if ((value as any).navCode === categoryCode) {
      return Number.parseInt(key); // keys in objects are always of the type string
    }
  }

  return undefined;
}

export function getCategoryName(categoryCode: string, categories?: Array<CategoryListItem>): string {
  const categoryId = getCategoryId(categoryCode);

  if (categoryId === undefined) {
    return categoryCode;
  }

  return categories?.find(c => c.id === categoryId)?.name ?? categoryCode;
}
