import { SortOrder, SortableFields } from "~/graphql/propeller/generated";
import type {
  FilterGroupMultiCheck,
  FilterGroupMultiSelect,
  FilterOptionMultiCheck,
  TextFiltersType,
} from "~/models/filters";
import type { CategoryListItem, ProductListItem, ProductSearchQueryParams } from "~/models/products";
import type { SearchProductsRequest } from "~/server/api/ecom/products/index.get";

interface CatalogQueryParams {
  page?: number | null;
  categoryId?: number | null;
  term?: string | null;
  textFilters?: string | null;
  sortField?: string | null;
  sortOrder?: string | null;
}

const _defaultPage = 1;
const _defaultSortField = SortableFields.name;
const _defaultSortOrder = SortOrder.asc;

function getTextFiltersAsJson(textFilters: string) {
  try {
    return JSON.parse(textFilters) as TextFiltersType;
  }
  catch (e) {
    console.warn("Invalid text filters in query string", e);
    return undefined;
  }
}

export async function useCatalog(categories?: Array<CategoryListItem>) {
  const { trackProductList, trackSearch } = useGtmTracking();
  const { locale } = useI18n();
  const { meta } = useRoute();
  const { push } = useRouter();
  const localePath = useLocalePath();

  const searchType = computed(() => meta.catalogSearchType ?? "catalog");
  // console.log("Entered useCatalog", fullPath, meta.catalogSearchType, params.slug);

  // below states need to be global states so every instance of this composable works with the same values
  const products = useCatalogProducts();
  const filters = useCatalogFilters();
  const pagination = useCatalogPagination();
  const searchQuery = useCatalogSearchQuery();

  const { pending, execute, error } = await useFetch("/api/ecom/products", {
    // server: false,
    lazy: true,
    immediate: false,
    dedupe: "defer",
    query: computed(() =>
      ({
        ...searchQuery.value,
        locale: locale.value,
        searchType: searchType.value,
      } as SearchProductsRequest),
    ),
    watch: false,
    deep: false,
    onRequest: ({ request, options }) => {
      // eslint-disable-next-line no-console
      console.log(`fetch request ${JSON.stringify(request)} ${JSON.stringify(options.query)}`);
    },
    onResponse: async ({ response }) => {
      // console.log("fetch products", response._data);
      filters.value = await mapToFilterGroups(response._data.filters, categories ?? []);
      products.value = response._data.products as ProductListItem[];
      pagination.value.pageSize = response._data.pagination.pageSize;
      pagination.value.totalItems = response._data.pagination.totalItems;

      if (products.value && products.value.length > 0) {
        let listName = "Results";

        switch (meta.catalogSearchType) {
          case "promotion":
            listName = "Promotion";
            break;
          case "selection":
            listName = "Selection";
            break;
        }

        trackProductList(products.value, listName);
      }
    },
    onResponseError: ({ response }) => {
      console.error("Could not get catalog data", response);
    },
  });

  const setParamsAndExecute = async (params: CatalogQueryParams) => {
    searchQuery.value = {
      ...searchQuery.value,
      page: params.page || _defaultPage,
      sortField: params.sortField ? mapToSortField(params.sortField) : SortableFields.name,
      sortOrder: params.sortOrder ? mapToSortOrder(params.sortOrder) : SortOrder.asc,
      categoryId: params.categoryId || undefined,
      term: params.term || undefined,
      textFilters: params.textFilters ? getTextFiltersAsJson(params.textFilters) : undefined,
    };

    await execute();
  };

  const setParamsAndNavigate = async (params: CatalogQueryParams) => {
    const queryParams: ProductSearchQueryParams = {
      page: _defaultPage,
      sortField: _defaultSortField,
      sortOrder: _defaultSortOrder,
    };

    if (params.categoryId) {
      queryParams.categoryId = params.categoryId;
    }

    if (params.term) {
      queryParams.term = params.term;
      trackSearch(params.term);
    }

    if (params.textFilters) {
      queryParams.textFilters = params.textFilters;
    }

    await navigateTo(localePath(`/catalog?${new URLSearchParams(queryParams as any).toString()}`));
  };

  // when a search param gets updated in the state, reflect the change in the url query params
  watch(searchQuery, (newSearchQuery) => {
    // //console.log("searchQuery changed");
    let newQueryParams: ProductSearchQueryParams = {
      page: newSearchQuery.page,
      sortField: newSearchQuery.sortField,
      sortOrder: newSearchQuery.sortOrder!,
    };

    if (newSearchQuery.categoryId) {
      newQueryParams = {
        ...newQueryParams,
        categoryId: newSearchQuery.categoryId,
      };
    }

    if (newSearchQuery.term && newSearchQuery.term.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        term: newSearchQuery.term.toString(),
      };
    }

    if (newSearchQuery.textFilters && newSearchQuery.textFilters.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        textFilters: JSON.stringify(newSearchQuery.textFilters),
      };
    }

    push({
      query: {
        page: newQueryParams.page,
        sortField: newQueryParams.sortField,
        sortOrder: newQueryParams.sortOrder,
        categoryId: newQueryParams.categoryId ?? undefined,
        term: newQueryParams.term ?? undefined,
        textFilters: newQueryParams.textFilters ?? undefined,
      },
    });
  }, {
    deep: true,
  });

  watch(() => searchQuery.value.page, () => document.body.scrollIntoView({ behavior: "smooth" }));

  const page = computed({
    get: () => {
      return searchQuery.value.page;
    },
    set: async (newPage: number) => {
      searchQuery.value.page = newPage;

      await execute();
    },
  });

  const sortValue = computed({
    get: () => {
      return `${searchQuery.value.sortField}-${searchQuery.value.sortOrder}`;
    },
    set: async (newSortField: string) => {
      if (searchQuery.value.sortField === newSortField) {
        return;
      }

      const splitValues = newSortField.split("-");
      searchQuery.value.sortField = mapToSortField(splitValues[0]!);
      searchQuery.value.sortOrder = mapToSortOrder(splitValues[1]!);
      searchQuery.value.page = 1;

      await execute();
    },
  });

  const textFilters = computed<TextFiltersType | undefined > ({
    get: () => {
      return searchQuery.value.textFilters;
    },
    set: async (newTextFilters?: TextFiltersType) => {
      if (searchQuery.value.textFilters === newTextFilters) {
        return;
      }

      searchQuery.value.textFilters = newTextFilters;
      searchQuery.value.page = 1;

      await execute();
    },
  });

  const categoryId = computed<number | undefined>({
    get: () => {
      return searchQuery.value.categoryId;
    },
    set: async (newCategoryId?: number) => {
      if (searchQuery.value.categoryId === newCategoryId) {
        return;
      }

      searchQuery.value.categoryId = newCategoryId;
      searchQuery.value.textFilters = undefined;
      searchQuery.value.page = 1;

      await execute();
    },
  });

  const term = computed<string | undefined>({
    get: () => {
      return searchQuery.value.term;
    },
    set: async (newTerm?: string) => {
      if (searchQuery.value.term === newTerm) {
        return;
      }

      searchQuery.value.term = newTerm;
      searchQuery.value.textFilters = undefined;
      searchQuery.value.page = 1;

      await execute();
    },
  });

  const hasItems = computed<boolean>(() => {
    return pagination.value.totalItems > 0;
  });

  // https://blog.logrocket.com/how-to-use-type-guards-typescript
  function isMultiSelect(filterGroup: FilterGroupMultiCheck | FilterGroupMultiSelect) {
    return typeof filterGroup === "object" && filterGroup !== null && "selectedList" in filterGroup;
  }

  const updateTextFilters = () => {
    const newTextFilters = [];

    for (const filterGroup of filters.value) {
      if (isMultiSelect(filterGroup)) {
        if ((filterGroup as FilterGroupMultiSelect).selectedList?.length) {
          newTextFilters.push({
            searchId: filterGroup.searchId,
            values: (filterGroup as FilterGroupMultiSelect).selectedList,
          });
        }
      }
      else {
        if (filterGroup.options.filter((o: FilterOptionMultiCheck) => o.selected).length > 0) {
          newTextFilters.push({
            searchId: filterGroup.searchId,
            values: filterGroup.options
              .filter((o: FilterOptionMultiCheck) => o.selected)
              .map((o: FilterOptionMultiCheck) => o.value),
          });
        }
      }
    }

    textFilters.value = newTextFilters as any;
  };

  function resetCatalog() {
    const searchTerm = useSearchTerm();
    searchTerm.value = "";

    setParamsAndExecute({
      term: undefined,
      page: _defaultPage,
      sortField: _defaultSortField,
      sortOrder: _defaultSortOrder,
    });
  }

  return {
    pending: pending,
    error: error,
    hasItems: hasItems,
    products: products,
    filters: filters,
    pagination: pagination,
    page: page,
    sortValue: sortValue,
    categoryId: categoryId,
    term: term,
    textFilters: textFilters,
    searchType: searchType,
    updateTextFilters: updateTextFilters,
    setParamsAndExecute: setParamsAndExecute,
    setParamsAndNavigate: setParamsAndNavigate,
    resetCatalog: resetCatalog,
  };
}
