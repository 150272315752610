import { SortOrder, SortableFields } from "~/graphql/propeller/generated";

const fieldStringToEnum: Map<string, ProductSortField> = new Map([
  [SortableFields.name, SortableFields.name],
  [SortableFields.relevance, SortableFields.relevance],
  [SortableFields.supplierCode, SortableFields.supplierCode],
]);

const orderStringToEnum: Map<string, ProductSortOrder> = new Map([
  [SortOrder.asc, SortOrder.asc],
  [SortOrder.desc, SortOrder.desc],
]);

export function mapToSortField(field: string): ProductSortField {
  return (fieldStringToEnum.get(field) ?? SortableFields.name) as ProductSortField;
}

export function mapToSortOrder(order: string): ProductSortOrder {
  return (orderStringToEnum.get(order) ?? SortOrder.asc) as ProductSortOrder;
}

export type ProductSortField = SortableFields.name | SortableFields.relevance | SortableFields.supplierCode;
export type ProductSortOrder = SortOrder.asc | SortOrder.desc;
